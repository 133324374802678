import axios from "axios";

const config = require("../ini.json")

export const api = axios.create({
    //baseURL: 'https://jorge.itajai.sc.gov.br/portaladm-pmitajai/api/'
    //baseURL: 'http://appdev/pmi-intranet2/felipe.araujo/public/portaladm-pmitajai/api/'
    baseURL: `${config.api_url}portaladm-pmitajai/api/`
})

//export const domain = 'http://localhost:3000/'
//export const domain = 'https://prefeituraitajai-69ed6.web.app/'
//export const domain = config.domain_url

export const urls = {
    ListNews: 'noticias',
    ListCategories: 'categorias',
    NewsById: 'noticia/cod_noticia/',
    NewsByCategorie: 'noticias/cod_categoria/',
    NewsByPage: 'noticias/pagina/',
    Services: 'servicos',
    Banners: 'banners',
    videosDestaque: 'videos-destaque',
    VideosObject: 'videos',
    menus: 'menus',
    Pesquisa: 'pesquisa/termo/',
    Article: 'artigo/cod_artigo/',
    Entity: 'entidade/cod_entidade/',
    AcessoRapido: 'acesso-rapido',
    Modal: 'modal',
}