import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate
} from "react-router-dom"

import { HomeScreen } from "../pages/Home"
import { ListNewsScreen } from "../pages/ListNews"
import { NewsScreen } from "../pages/News"
import { AppProvider } from "../context/app"
import { ArticleScreen } from "../pages/Article"
import { ContactScreen } from "../pages/Contact"
import { LocationScreen } from "../pages/Location"
import { SearchScreen } from "../pages/Search"
import { EntityScreen } from "../pages/Entity"
import { ListVideosScreen } from "../pages/ListVideos"
import { NotFoundScreen } from "../pages/NotFound"

export function AppRoutes() {
    return (
        <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/e/null" element={<HomeScreen />} />
            <Route path="/a/null" element={<HomeScreen />} />
            <Route path="/null" element={<HomeScreen />} />
            <Route path="/noticias" element={<ListNewsScreen />} />
            <Route path="/noticias/:id/:title" element={<NewsScreen />} />
            <Route path="/noticias/:id" element={<NewsScreen />} />
            <Route path="/e/:id" element={<ArticleScreen />} />
            <Route path="/a/:id" element={<EntityScreen />} />
            <Route path="/contato" element={<ContactScreen />} />
            <Route path="/telefones" element={<ContactScreen />} />
            <Route path="/localizacao" element={<LocationScreen />} />
            <Route path="/busca/:searchText" element={<SearchScreen />} />
            <Route path="/busca" element={<SearchScreen />} />
            <Route path="/videos" element={<ListVideosScreen />} />
            <Route path="/NotFound" element={<NotFoundScreen />} />
			<Route path="*" element={<NotFoundScreen />} />
			<Route path="/iptu" element={<Navigate to="/e/180" />} />
        </Routes>
    )
}