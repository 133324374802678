import Icon from '@mdi/react';
import { mdiMagnify } from '@mdi/js';
import logo from '../../assets/imgs/logo.png';
import { HomeIcon } from '../icons/common/home';
import { api, urls } from '../../services/api';
import { useEffect, useState } from 'react';
import { IMenuItem } from '../../interfaces/menu';
import { ItemMenuNav } from './components/ItemMenuNav';
import './style.css';
import { useApp } from '../../context/app';
import { useLocation } from 'react-router-dom';

interface Props {
	search?: boolean;
	dataSearch?: any[];
	onChange?: (array: any[]) => void;
	route?: string;
}

export function Header({ search = false, route }: Props) {
	const { configDomainURL } = useApp();
	const domain = configDomainURL;
	const [menu, setMenu] = useState<IMenuItem[]>([]);
	const [idMenu, setIdMenu] = useState<number>(0);
	const [inputSearch, setInputSearch] = useState<string>('');
	const location = useLocation(); // Hook para capturar a URL atual

	useEffect(() => {
		(async () => {
			const response = await api.get(urls.menus);
			setMenu(response.data);
		})();
	}, []);

	// Verifica a URL para identificar os diferentes caminhos
	const currentPath = location.pathname;
	const isNoticiasPage = currentPath === '/noticias/'; // Verifica se estamos na página "/noticias/"
	const isNoticiasSubPage = currentPath.startsWith('/noticias/') && currentPath !== '/noticias/'; // Verifica se é uma subpágina de "/noticias/"
	const pathSegments = currentPath.split('/');
	const subPageName = pathSegments[pathSegments.length - 1].replace(/-/g, ' '); // Substitui "-" por espaço

	return (
		<>
			<div className={!search ? 'Header' : 'Header Header-pagina-interna'}>
				<div className="Header-itens">
					<a href={domain}>
						<img src={logo} />
					</a>
					<div className="Header-button-box">
						{search && (
							<form
								className="Header-search-form"
								action={`${domain}busca/${inputSearch}`}
							>
								<input
									className="Header-search-form-input"
									placeholder="Buscar"
									onChange={(e) => setInputSearch(e.target.value)}
								/>
								<button className="Header-search-form-button" type="submit">
									<Icon path={mdiMagnify} color={'white'} />
								</button>
							</form>
						)}
						<div className="Header-navBarHover" id="navBarHover">
							<div className="Header-btn">
								<a href={domain}>
									<HomeIcon />
									Início
								</a>
							</div>
							{menu &&
								menu.map((item, index) => (
									<ItemMenuNav
										key={index}
										idActual={idMenu}
										itemMenu={item}
										onChange={setIdMenu}
										count={index + 1}
									/>
								))}
						</div>
					</div>
				</div>
			</div>

			{route && (
				<div className="Header-breadcrumb">
					<div className="Header-breadcrumb-item">
						<a href="/">Inicial</a> /

						{/* Caso a URL seja "/noticias/" */}
						{isNoticiasPage && (
							<>
								<a href="/noticias">Notícias</a>
							</>
						)}

						{/* Caso a URL seja uma subpágina de "/noticias/" */}
						{isNoticiasSubPage && (
							<>
								<a href="/noticias"> Notícias</a> / <span>{subPageName}</span>
							</>
						)}

						{/* Caso a URL não seja relacionada a "/noticias/" */}
						{!isNoticiasPage && !isNoticiasSubPage && route && <span>{route}</span>}
					</div>
				</div>
			)}
		</>
	);
}
