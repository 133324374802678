import { useParams, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useApp } from "../../context/app";
import { Header } from "../../components/Header";
import { HeaderMobile } from "../../components/HeaderMobile";
import { SearchMobile } from "../../components/SearchMobile";
import { TitlePage } from "../../components/TitlePage";
import { Footer } from "../../components/Footer";
import VLibras from '@djpfs/react-vlibras';

import '../../assets/css/root.css';
import '../../assets/css/global.css';
import '../../assets/css/tema_escuro.css';
import './style.css';

export function NotFoundScreen() {
	
	const { mobile, menuMobile, visible } = useApp()
	const location = useLocation();

	return (
		<div className="pages-NotFound">
			
			{
				
				menuMobile ? 
				
				(
				
					<HeaderMobile />
					
				) 
				
				: 
				
				(

					<Header 
						search 
						route={` Erro 404`}
					/>
					
				)
				
			}
			
			{
				
				mobile && 
				
				<SearchMobile />
				
			}

			<TitlePage title={'Erro 404'} />
			
			<div className="pages-NotFound-container">
			
				<p>Ops... Este endereço está errado:</p>
				<p>{window.location.href}</p>
			</div>
			
			<div className="pages-NotFound-separador"></div>
			
			<Footer />
			
			{
				
				visible && 
				
				(
				
					<VLibras forceOnload />
					
				)
				
			}
			
		</div>
	)

}