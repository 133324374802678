import { useEffect, useState } from "react";
import { useApp } from "../../context/app";
import { GridIcon } from "../icons/common/grid";
import { ListIcon } from "../icons/common/list";
import "./style.css";

interface Props {
	title: string;
	iconNews?: boolean;
	onChange?: (value: boolean) => void;
}

export function TitlePage({ title, onChange, iconNews = false }: Props) {
	const { mobile } = useApp();
	const [listGrid, setListGrid] = useState<boolean>(true);
	const [isNoticiasPage, setIsNoticiasPage] = useState<boolean>(false);

	// Atualiza o estado listGrid quando há mudança
	useEffect(() => {
		if (onChange) {
			onChange(listGrid);
		}
	}, [listGrid, onChange]);

	// Verifica se a URL atual é exatamente "/noticias/"
	useEffect(() => {
		const currentPath = window.location.pathname;
		setIsNoticiasPage(currentPath === "/noticias/" || currentPath === "/noticias");
	}, []);

	return (
		<div className="TitlePage">
			<div className="TitlePage-iconNews">
				<div className="TitlePage-iconNews-titulo">{title}</div>

				{/* Exibe os ícones apenas na página "/noticias/" */}
				{isNoticiasPage && (
					<div
						className="TitlePage-iconNews-icone"
						onClick={() => setListGrid(!listGrid)}
					>
						{listGrid ? <GridIcon /> : <ListIcon />}
					</div>
				)}
			</div>

			<div className="TitlePage-bottom">
				<div className="TitlePage-bottom-amarelo" />
				<div className="TitlePage-bottom-bar" />
			</div>
		</div>
	);
}
