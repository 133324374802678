import { SubTitlePage } from "../SubTitlePage";
import { useApp } from "../../context/app";
import { Fechar } from '../icons/common/fechar'
import './style.css';

interface Props {
	gallery: string[]
	download: string
}

export function ListImages({ gallery, download }: Props) {

	const { mobile } = useApp()

	const abrirLightbox = function (img_url: string, img_original: string) {

		//console.log('ListImages ln 16 - img_url')
		//console.log(img_url)

		let escurecer = document.querySelector('.ListImages-lightbox-escurecer')
		let lightbox = document.querySelector('.ListImages-lightbox')

		if (escurecer) { escurecer.classList.add("ListImages-lightbox-ativo") }
		if (lightbox) {
			lightbox.classList.add("ListImages-lightbox-ativo")
			lightbox.innerHTML = '<a href="' + img_original + '" target="_blank"><div class="ListImages_lightbox_conteudo" style="background-image:url(' + img_url + ')"></div></a>'
		}

	}

	const fecharLightbox = function () {

		let escurecer = document.querySelector('.ListImages-lightbox-escurecer')
		let lightbox = document.querySelector('.ListImages-lightbox')

		if (escurecer) { escurecer.classList.remove("ListImages-lightbox-ativo") }
		if (lightbox) { lightbox.classList.remove("ListImages-lightbox-ativo") }

	}

	//console.log('gallery', gallery)

	return (
		<>
			<div className="ListImages">

				<SubTitlePage title="Imagens Relacionadas" />

				{

					!mobile ?

					<>

						<div className="ListImages-itens">
							{
								gallery.map((item) => {

									const thumbArray = item.split("/");
									const nome_imagem = thumbArray[thumbArray.length - 1];
									const thumb_url_original = 'https://api-portal.itajai.sc.gov.br/public/portaladm-pmitajai/noticias/original/'
									const thumb_url_foto = 'https://api-portal.itajai.sc.gov.br/public/portaladm-pmitajai/noticias/foto/'
									const thumb_url_tn = 'https://api-portal.itajai.sc.gov.br/public/portaladm-pmitajai/noticias/tn/'
									const thumb = thumb_url_foto + nome_imagem
									const img_original = thumb_url_original + nome_imagem

									//console.log('item', item)
									//console.log('thumbArray', thumbArray)
									//console.log('nome_imagem', nome_imagem)

									return (
									
										<a key={nome_imagem} onClick={() => abrirLightbox(thumb, img_original)} >

											{
												item !== 'https://api-portal.itajai.sc.gov.br/public/portaladm-pmitajai/noticias/foto/.jpg' &&

												<img
													src={thumb}
													className="ListImages-img"
												/>

											}
											
										</a>
										
									)
									
								})
								
							}
							
						</div>

						{

							!!download &&

							<a
								href={download}
								target="_blank"
							>
								<div className="ListImages-download">Fazer download das imagens</div>
							</a>
							
						}
						
					</>

					:

					<div className="ListImages-mobile-itens">

						{
							gallery.map((item) => {

								const mobile_thumbArray = item.split("/");
								const mobile_nome_imagem = mobile_thumbArray[mobile_thumbArray.length - 1];
								const mobile_thumb_url_original = 'https://api-portal.itajai.sc.gov.br/public/portaladm-pmitajai/noticias/original/'
								const mobile_thumb_url_foto = 'https://api-portal.itajai.sc.gov.br/public/portaladm-pmitajai/noticias/foto/'
								const mobile_thumb_url_tn = 'https://api-portal.itajai.sc.gov.br/public/portaladm-pmitajai/noticias/tn/'
								const mobile_thumb = mobile_thumb_url_foto + mobile_nome_imagem
								const mobile_img_original = mobile_thumb_url_original + mobile_nome_imagem

								return (
									<a onClick={() => abrirLightbox(mobile_thumb, mobile_img_original)} >

										{
											item !== 'https://api-portal.itajai.sc.gov.br/public/portaladm-pmitajai/noticias/foto/.jpg' &&

											<img
												src={mobile_thumb}
												className="ListImages-mobile-img"
											/>

										}

									</a>
								)
								
							})
							
						}

						{
							
							!!download &&

							<a
								href={download}
								target="_blank"
							>
								<div className="ListImages-download">Fazer download das imagens</div>
							</a>
							
						}
						
					</div>
					
				}

				<div className="ListImages-lightbox-escurecer">
					<div
						className="ListImages-lightbox-fechar"
						onClick={() => fecharLightbox()}
					><Fechar /></div>
				</div>
				<div className="ListImages-lightbox"></div>
				
			</div>
			
			{/*<div className="ListImages_full"></div>*/}
			
		</>
	)

}