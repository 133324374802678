/***********************************************************************************

ATENÇÃO

OS DADOS DESTA PÁGINA ESTÃO NO ARTIGO 179 

https://adm-portal.itajai.sc.gov.br/portaladm-pmitajai/artigo/detalhe/cod_artigo/179

EM HTML

CRIEI UM TXT: texto-original.txt

NELE TEM O TEXTO HTML PARA SUBSTITUIR CASO ALGO DÊ ERRADO

***********************************************************************************/

import { useEffect, useState } from "react";
import { api, urls } from "../../services/api";
import { Header } from "../../components/Header";
import { TitlePage } from "../../components/TitlePage";
import { MapSite } from "../../components/MapSite";
import { Footer } from "../../components/Footer";
import { ListContact } from "./components/ListContact/listContact";
import { useApp } from "../../context/app";
import { HeaderMobile } from "../../components/HeaderMobile";
import { SearchMobile } from "../../components/SearchMobile";
import { ListContactMobile } from "./components/ListContactMobile/listContact";
import { IArticle } from "../../interfaces/article";
import VLibras from '@djpfs/react-vlibras';
import '../../assets/css/root.css';
import '../../assets/css/global.css';
import '../../assets/css/tema_escuro.css';
import './style.css';

export function ContactScreen() {
	
	const { mobile, menuMobile, visible } = useApp()
	
	const [article, setArticle] = useState<IArticle>(
		{
			content: '',
			published: '',
			title: 'Carregando...'
		}
	)
	
	const getArticleById = async (id: number) => {
		
		try {
			
			const response = await api.get(urls.Article + id)
			setArticle(response.data)
			
		} catch (error) {
			console.log('Contact getArticleById - Erro: ', error)
		}
		
	}
	
	useEffect(() => {

		getArticleById( 179 )
		
	}, [])
	
	return (
		<div className="pages-Contact">
			
			{
				
				menuMobile ? 
				
				(
				
					<HeaderMobile />
					
				) 
				
				: 
				
				(

					<Header 
						search 
						route={` Contato`}
					/>
					
				)
				
			}
			
			{
				
				mobile && 
				
				<SearchMobile />
				
			}

			<TitlePage title={'Lista de telefones da Prefeitura de Itajaí'}/>
			
			{

				article.content ? (

					<div 
						className="pages-Contact-box"
						dangerouslySetInnerHTML={{ __html: article.content }} 
					/>
					
				) 
				
				: 
				
				(

					<div>Sem texto</div>
					
				)
				
			}
			
			<MapSite />
			
			<Footer />
			
			{
				
				visible && 
				
				(
				
					<VLibras forceOnload />
					
				)
				
			}
			
		</div>
		
	)
	
}