export function parseDate(date: string) {

    const dateSplit = date.split('/')

    const monthsOfYear = [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
    ]

    const month = monthsOfYear[parseInt(dateSplit[1])-1].toUpperCase()
	const year = new Date().getFullYear()

    const dateParsed = `${dateSplit[0]} ${month} ${year}`

    return dateParsed

}